<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Managemen Tema</span>
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-form :form="form" @submit="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <a-form-item label="Gambar Halaman Login" class="col-md-4">
                      <a-upload
                        name="image"
                        listType="picture-card"
                        class="avatar-uploader"
                        :showUploadList="false"
                        :beforeUpload="beforeUpload"
                        @change="onFileImageLoginChange">
                        <img
                          v-if="imageLoginUrl"
                          :src="imageLoginUrl"
                          alt="image"
                          class="img-fluid"/>
                        <div v-else>
                          <a-icon :type="loadingLogin ? 'loading' : 'plus'"/>
                          <div class="ant-upload-text">Pilih Gambar</div>
                        </div>
                      </a-upload>
                    </a-form-item>
                    <a-form-item label="Gambar Halaman Registrasi" class="col-md-4">
                      <a-upload
                        name="image"
                        listType="picture-card"
                        class="avatar-uploader"
                        :showUploadList="false"
                        :beforeUpload="beforeUpload"
                        @change="onFileImageRegisterChange">
                        <img
                          v-if="imageRegisterUrl"
                          :src="imageRegisterUrl"
                          alt="image"
                          class="img-fluid"/>
                        <div v-else>
                          <a-icon :type="loadingRegister ? 'loading' : 'plus'"/>
                          <div class="ant-upload-text">Pilih Gambar</div>
                        </div>
                      </a-upload>
                    </a-form-item>
                    <a-form-item label="Gambar Halaman Lupa Password" class="col-md-4">
                      <a-upload
                        name="image"
                        listType="picture-card"
                        class="avatar-uploader"
                        :showUploadList="false"
                        :beforeUpload="beforeUpload"
                        @change="onFileImageResetPasswordChange">
                        <img
                          v-if="imageResetPasswordUrl"
                          :src="imageResetPasswordUrl"
                          alt="image"
                          class="img-fluid"/>
                        <div v-else>
                          <a-icon :type="loadingResetPassword ? 'loading' : 'plus'"/>
                          <div class="ant-upload-text">Pilih Gambar</div>
                        </div>
                      </a-upload>
                    </a-form-item>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-with-addon">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-send"/>
                    </span>
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>

import { getTheme, updateTheme } from '@/api/theme.api';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      imageLoginUrl: '',
      thumbnailLogin: null,
      loadingLogin: false,
      imageRegisterUrl: '',
      thumbnailRegister: null,
      loadingRegister: false,
      imageResetPasswordUrl: '',
      thumbnailResetPassword: null,
      loadingResetPassword: false,
    }
  },
  mounted() {
    this.fetchTheme();
  },
  methods: {
    beforeUpload() {
      return false;
    },
    fetchTheme() {
      getTheme().then((response) => {
        const { data } = response;
        if(data) {
          this.imageLoginUrl = data.image_login_url;
          this.imageRegisterUrl = data.image_registration_url;
          this.imageResetPasswordUrl = data.image_forgotpassword_url;
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.$nprogress.start();
          let formData = new FormData();
          if (this.thumbnailLogin != null) {
            formData.set("image_login_url", this.thumbnailLogin);
          }
          if (this.thumbnailRegister != null) {
            formData.set("image_registration_url", this.thumbnailRegister);
          }
          if (this.thumbnailResetPassword != null) {
            formData.set("image_forgotpassword_url", this.thumbnailResetPassword);
          }
          this.submitData(formData);
        }
      })
    },
    onFileImageLoginChange(info) {
      if (this.checkImageUpload(info.file)) {
        getBase64(info.file, imageUrl => {
          this.imageLoginUrl = imageUrl;
          this.loadingLogin = false;
        });
        this.thumbnailLogin = info.file;
      }
    },
    onFileImageRegisterChange(info) {
      if (this.checkImageUpload(info.file)) {
        getBase64(info.file, imageUrl => {
          this.imageRegisterUrl = imageUrl;
          this.loadingRegister = false;
        });
        this.thumbnailRegister = info.file;
      }
    },
    onFileImageResetPasswordChange(info) {
      if (this.checkImageUpload(info.file)) {
        getBase64(info.file, imageUrl => {
          this.imageResetPasswordUrl = imageUrl;
          this.loadingResetPassword = false;
        });
        this.thumbnailResetPassword = info.file;
      }
    },
    checkImageUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      var validFormat = true;
      if (!isJpgOrPng) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Format Foto tidak Valid"
        });
        validFormat = false;
      }
      const isLt2M = file.size / 1024 <= 20480;
      if (!isLt2M) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Size terlalu besar!"
        });
        validFormat = false;
      }
      return validFormat;
    },
    async submitData(params) {
      try {
        let response = await updateTheme(params);
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Berhasil memperbaharui tema"
          });
        }
      } catch (error) {
        /**/
      }
      this.$nprogress.done();
    },
  }
}
</script>
