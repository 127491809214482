import httpClient from "@/api/httpClient";

const GET_THEME = "/api/theme"
const UPDATE_THEME = "/api/theme/update";

/**
 * Get Profile
 */
const getTheme = () => httpClient.get(GET_THEME);
const updateTheme = payload => httpClient.post(UPDATE_THEME,payload)

export {
  getTheme,
  updateTheme
};
